import React from 'react';
import GatsbyLink from '../GatsbyLink'
import './AddToQuote.scss'

export const AddToQuote = ({quoteType, productId}) => {
    return (
      <div className="product-quote-action">
        <GatsbyLink className="action" to={`/quote/?type=${quoteType}&product=${productId}`}>Get a quote</GatsbyLink>
      </div>
    )
}
