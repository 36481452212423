import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import { RelatedPass } from '../Posts/Related/RelatedPass'
import './RelatedProducts.scss';

const RelatedProducts = (props) => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          siteSettings: wordpressAcfOptions(options: {}) {
            options {
              showAuthor
            }
          },
          allWordpressWpProducts(
            sort: { fields: date, order: DESC }
          ) {
            edges {
              node {
                ...ProductListFields
              }
            }
          }
        }
      `}
      render={data => {
        const {category = null} = props
        if (!category) return null
        let products = category ? data.allWordpressWpProducts.edges.filter(a => a.node.product_categories.includes(category)) : data.allWordpressWpProducts.edges
        return (
          <RelatedPass {...props} posts={products} siteSettings={data.siteSettings.options} contentType="products" items={4} limit={12} />
        )
      }}
    />
  );
}

export default RelatedProducts
